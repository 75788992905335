import { _importEnd, _mainEnd, _varsStart, _funcStart, _returnStart, _returnEnd, _function  } from '../../Helper';
import './Print2.css';
import * as moment from 'moment';
import React, { useState, useEffect, useRef  } from 'react';
import { Image  } from 'react-bootstrap';
import { useSelector  } from 'react-redux';
import { useParams, Link  } from 'react-router-dom';
import { useReactToPrint  } from 'react-to-print';
_importEnd()

function Print2() {

    _varsStart()
    const componentRef = useRef();
    const { docData } = useSelector((state) => state.docData)
    const [currDate, setCurrDate] = useState(moment().format('DD-MM-YYYY'));

    _funcStart()
    useEffect(() => {
        
    }, []);

    _function()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,

    })

    _returnStart()
	return (
		<div className='p-5'>
			<div className='row'>
				<div className='right py-5 col-12'>
					<Link className='btn btn-sm btn-primary' style={{marginRight: '5px'}} to='/invoice-docs'>
						Back
					</Link>
					<button className='btn btn-sm btn-primary' onClick={handlePrint}>
						Print
					</button>
				</div>
			</div>
			<div className='row p-0 m-0' style={{height: 'window.innerHeight', width: '100%', backgrounbdColor: '#fff'}} ref={componentRef}>
				<div className='p-0 m-0 col-12'>
					<div className='p-0'>
						<div className='p-0 m-0 col-12'>
							<Image style={{width: '100%'}} src={require('../../assets/header1.png')}>
							</Image>
						</div>
					</div>
					<div className='px-5' style={{marginTop: '100px'}}>
						<div className='right col-12'>
							<label style={{fontWeight: 'bold'}}>
								Date: { currDate }
							</label>
						</div>
					</div>
					<div className='px-5'>
						<div className='col-12'>
						<label style={{fontWeight: 'bold'}}>
							To,
						</label>
						</div>
						<div className='col-12'>
						<label style={{fontWeight: 'bold'}}>
							{ docData.to_name }
						</label>
						</div>
						<div className='col-12'>
						<label style={{fontWeight: 'bold'}}>
							{ docData.to_address }
						</label>
						</div>
					</div>
					<div className='px-5 mt-5'>
						<div className='text-center col-12'>
							<label style={{fontWeight: 'bold'}}>
								<u>Quality Conformance Certificate</u>
							</label>
						</div>
					</div>
					<div className='px-5' style={{marginTop: '50px'}}>
						<div className='col-12'>
						<label >
							This is to certify that the item named <strong>"{docData.item_descr} {docData.lf_no}"</strong> dispatched
by Invoice Number <strong>{docData.invoice_no}</strong> against the contract <strong>{docData.order_no}</strong>, is manufactured as
per the Quality Conformance of <strong>{docData.buyer_name}</strong> by
M/s. PRECISION ADVANCED SYSTEMS, Aligarh up to1 year in case of any manufacturing defects.
						</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
    _returnEnd()
}

export default Print2;
_mainEnd()
