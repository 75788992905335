import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const cacheSlice = createSlice({
	name: 'xpedio-ebs-defense',
	initialState: {
		'docData': {}
	},
	reducers: {
		setDocData: (state, action) => {
			state.docData = action.payload
		}
	},
})

export const { setDocData } = cacheSlice.actions

export default cacheSlice.reducer