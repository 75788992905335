import { _importEnd, _mainEnd, _varsStart, _funcStart, _returnStart, _returnEnd, _function  } from '../../Helper';
import { callServerApi  } from '../../services/DataService';
import './Home.css';
import React, { useState, useEffect  } from 'react';
import { Image, Form, Row, Col  } from 'react-bootstrap';
import { useForm  } from 'react-hook-form';
import { Link, useNavigate  } from 'react-router-dom';
_importEnd()

function Home() {

    _varsStart()
    const [defenseInvoiceList, setDefenseInvoiceList] = useState([]);
    const [defenseInvoice, setDefenseInvoice] = useState({});
    let clause_jo = {};
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
    const [login, setLogin] = useState({});
    const [status, setStatus] = useState(null);
    const navigate = useNavigate();


    _funcStart()
    useEffect(() => {
    }, []);

    _function()
    const getDefenseInvoice = async () => {
        const result = await callServerApi('getDefenseInvoice', {}, {}, setDefenseInvoice);
        setDefenseInvoiceList(result.data);
    }

    _function()
    const onLogin = async (data) => {
        if (data.login.uname === 'admin' && data.login.passwd === 'invoice') {
            navigate('/invoice-docs')
        }
        else {
            setStatus('Invalid username or password.');
            setTimeout(() => {
                setStatus(null);
            }, 5000)
        }
    }

    _returnStart()
	return (
		<div className='p-5'>
			<div className='row'>
				<div className='col-4'>
				</div>
				<div className='col-4'>
					<div className='row shadow p-5' style={{marginTop: '100px'}}>
						<div className='col-12'>
						<Image src={require('../../assets/xpediolive.png')}>
						</Image>
						</div>
						<div className='col-12'>
						<Form.Group >
							<Form.Label htmlFor=''>
								Username
							</Form.Label>
							<Form.Control 
								type='text'
								placeholder=''
								{...register('login.uname', {  })}
							>
							</Form.Control>
						</Form.Group>
						</div>
						<div className='col-12'>
						<Form.Group >
							<Form.Label htmlFor=''>
								Password
							</Form.Label>
							<Form.Control 
								type='password'
								placeholder=''
								{...register('login.passwd', {  })}
							>
							</Form.Control>
						</Form.Group>
						</div>
						<div className='mt-5 text-center col-12'>
							<button className='btn btn-sm btn-primary' onClick={handleSubmit(onLogin)}>
								SIGN-IN
							</button>
							<Link to='#'>
								
							</Link>
							<div className='row'>
								<div className='col-12'>
								<label className='mt-4' style={{color: '#c70039', fontWeight: 'bold'}}>
									{ status }
								</label>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='col-4'>
				</div>
			</div>
		</div>
	)
    _returnEnd()
}

export default Home;
_mainEnd()
