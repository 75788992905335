import { _importEnd, _mainEnd, _varsStart, _funcStart, _returnStart, _returnEnd, _function  } from '../../Helper';
import './Print3.css';
import * as moment from 'moment';
import React, { useState, useEffect, useRef  } from 'react';
import { useSelector  } from 'react-redux';
import { useParams, Link  } from 'react-router-dom';
import { useReactToPrint  } from 'react-to-print';
_importEnd()

function Print3(){

    _varsStart()
    const componentRef = useRef();
    const { docData } = useSelector((state) => state.docData)
    const [currDate, setCurrDate] = useState(moment().format('DD-MM-YYYY'));

	_funcStart()
	useEffect(()=>{
    }, []);

    _function()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,

    })

	_returnStart()
	return (
		<div className='p-5'>
			<div className='row'>
				<div className='right col-12'>
					<Link className='btn btn-sm btn-primary' style={{marginRight: '5px'}} to='/invoice-docs'>
						Back
					</Link>
					<button className='btn btn-sm btn-primary' onClick={handlePrint}>
						Print
					</button>
				</div>
			</div>
			<div className='row p-5 mt-5' style={{height: 'window.innerHeight', width: '100%', backgrounbdColor: '#fff'}} ref={componentRef}>
				<div className='col-12'>
					<div className='row px-5' style={{marginTop: '400px'}}>
						<div className='col-12'>
						<label >
							{docData.item_descr }
						</label>
						</div>
						<div className='col-12'>
						<label >
							{docData.lf_no}
						</label>
						</div>
						<div className='col-12'>
						<label >
							{docData.order_no }
						</label>
						</div>
						<div className='col-12'>
						<label >
							{docData.item_qty } {docData.item_uom }
						</label>
						</div>
						<div className='col-12'>
						<label >
							{docData.invoice_no}
						</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
	_returnEnd()
}

export default Print3;
_mainEnd()
