import './App.css';
import { _importEnd, _mainEnd, _varsStart, _funcStart, _returnStart, _returnEnd  } from './Helper';
import Home from './admin/home/Home';
import React, { useState, useEffect  } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Link  } from 'react-router-dom';
import InvoiceDocs from './admin/invoice-docs/InvoiceDocs';
import Print1 from './admin/print1/Print1';
import Print2 from './admin/print2/Print2';
import Print3 from './admin/print3/Print3';
_importEnd()

function App(){

	_varsStart()
	const navigate = useNavigate();

	_funcStart()
	_returnStart()
	return (
		<div >
			<div className='row px-5 py-2 m-0 menu-bar'>
				<div className='col-12'>
				<Link className='logo' to='#'>
					XPEDIO-EBS
				</Link>
				</div>
			</div>
			<Routes>
				<Route exact path='/' element={<Home />} />
				<Route exact path='/invoice-docs' element={<InvoiceDocs/>} />
				<Route exact path='/print1' element={<Print1/>} />
				<Route exact path='/print2' element={<Print2/>} />
				<Route exact path='/print3' element={<Print3/>} />
			</Routes>
		</div>
	)
	_returnEnd()
}

export default App;
_mainEnd()
