import { _importEnd, _mainEnd, _varsStart, _funcStart, _returnStart, _returnEnd, _function } from '../../Helper';
import { setDocData } from '../../redux/cache';
import { callServerApi } from '../../services/DataService';
import './InvoiceDocs.css';
import React, { useState, useEffect } from 'react';
import { Table, Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
_importEnd()

function InvoiceDocs() {

    _varsStart()
    const dispatch = useDispatch();
    let clause_jo = {};
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
    const [d1Show, setD1Show] = useState(false)
    const [defenseInvoiceList, setDefenseInvoiceList] = useState([]);
    const [defenseInvoice, setDefenseInvoice] = useState({});
    const [status, setStatus] = useState(null);
    const navigate = useNavigate();
    _funcStart()
    useEffect(() => {
        getDefenseInvoice();
    }, []);

    _function()
    const closeD1 = () => setD1Show(false);
    _function()
    const showD1 = () => {
        setD1Show(true);
        clearRecordDefenseInvoice();
    }
    _function()
    const clearRecordDefenseInvoice = async () => {
        setValue('defenseInvoice', {});
    }

    _function()
    const deleteDefenseInvoice = async (rec) => {
        clause_jo['_id'] = rec._id;
        const result = await callServerApi('deleteDefenseInvoice', {}, clause_jo);
        getDefenseInvoice();
        setStatus(result.msg);
        setTimeout(() => {
            setStatus(null);
        }, 5000);
    }

    _function()
    const getDefenseInvoice = async () => {
        const result = await callServerApi('getDefenseInvoice', {}, {}, setDefenseInvoice);
        setDefenseInvoiceList(result.data);
    }

    _function()
    const saveDefenseInvoice = async (data) => {
        setDefenseInvoice(data.defenseInvoice);
        if (data.defenseInvoice._id === '') {
            delete data.defenseInvoice._id
        }
        const result = await callServerApi('saveDefenseInvoice', data.defenseInvoice, clause_jo);
        setD1Show(false);
        getDefenseInvoice();
        setStatus(result.msg);
        setTimeout(() => {
            setStatus(null);
        }, 5000);
    }

    _function()
    const selectCurrentRecordDefenseInvoice = async (rec) => {
        setValue('defenseInvoice', rec);
        setD1Show(true);
    }

    _function()
    const onPrint1 = async (e, rec, route) => {
        e.preventDefault();
        dispatch(setDocData(rec));
        navigate(route);
    }

    _returnStart()
    return (
        <div >
            <div className='row m-5 p-5 shadow' style={{ backgroundColor: '#fff' }}>
                <div className='col-3'>
                    <h1 className='header-18'>
                        Documents
				</h1>
                </div>
                <div className='col-6'>
                    <label style={{ color: '#c70039', fontWeight: 'bold' }}>
                        {status}
                    </label>
                </div>
                <div className='right col-3'>
                    <button className='btn btn-sm btn-primary' style={{ marginRight: '7px' }} onClick={(e) => showD1()}>
                        Add new
					</button>
                    <Link className='btn btn-sm btn-primary' to='/'>
                        Logout
					</Link>
                </div>
                <div className='col-12'>
                    <div className='row mt-4'>
                        <div className='col-12'>
                            <Table className='table table-bordered table-sm' id='T1'>
                                <thead>
                                    <tr>
                                        <th>Invoice no</th>
                                        <th>Order no</th>
                                        <th>LF no</th>
                                        <th>Buyer name</th>
                                        <th>Item qty</th>
                                        <th>Item UoM</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        defenseInvoiceList.map(x => {
                                            return (
                                                <tr key={x._id}>
                                                    <td>
                                                        <Link name='invoice_no' onClick={(e) => handleSubmit(selectCurrentRecordDefenseInvoice(x))} to='#'>
                                                            {x.invoice_no}
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        <label name='order_no'>
                                                            {x.order_no}
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <label name='lf_no'>
                                                            {x.lf_no}
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <label name='buyer_name'>
                                                            {x.buyer_name}
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <label name='item_qty'>
                                                            {x.item_qty}
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <label name='item_uom'>
                                                            {x.item_uom}
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <Link style={{ color: '#c70039' }} onClick={(e) => handleSubmit(deleteDefenseInvoice(x))} to='#'>
                                                            Delete
									</Link>
                                                    </td>
                                                    <td>
                                                        <Link onClick={(e) => onPrint1(e, x, '/print1')} to='#'>
                                                            Print 1
									</Link>
                                                    </td>
                                                    <td>
                                                        <Link onClick={(e) => onPrint1(e, x, '/print2')} to='#'>
                                                            Print 2
									</Link>
                                                    </td>
                                                    <td>
                                                        <Link onClick={(e) => onPrint1(e, x, '/print3')} to='#'>
                                                            Print 3
									</Link>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={d1Show} onHide={closeD1} backdrop='static' keyboard='false' size={'xl'}>
                <Modal.Header closeButton className='modal-header'>
                    <Modal.Title>Documents</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal-body'>
                    <div className='row'>
                        <div className='col-12'>
                            <Form.Group >
                                <Form.Label htmlFor=''>
                                    To name<span style={{ 'color': '#c70039' }}>*</span>
                                </Form.Label>
                                <Form.Control name='to_name'
                                    type='text'
                                    placeholder=''
                                    {...register('defenseInvoice.to_name', { required: 'Required field' })}
                                >
                                </Form.Control>
                                {errors.defenseInvoice && errors.defenseInvoice.to_name && <p style={{ 'color': '#c70039' }}>{errors.defenseInvoice.to_name.message}</p>}
                            </Form.Group>
                        </div>
                        <div className='col-12'>
                            <Form.Group >
                                <Form.Label htmlFor=''>
                                    To address<span style={{ 'color': '#c70039' }}>*</span>
                                </Form.Label>
                                <Form.Control name='to_address'
                                    type='text'
                                    placeholder=''
                                    {...register('defenseInvoice.to_address', { required: 'Required field' })}
                                >
                                </Form.Control>
                                {errors.defenseInvoice && errors.defenseInvoice.to_address && <p style={{ 'color': '#c70039' }}>{errors.defenseInvoice.to_address.message}</p>}
                            </Form.Group>
                        </div>
                        <div className='col-4'>
                            <Form.Group >
                                <Form.Label htmlFor=''>
                                    Invoice no<span style={{ 'color': '#c70039' }}>*</span>
                                </Form.Label>
                                <Form.Control name='invoice_no'
                                    type='text'
                                    placeholder=''
                                    {...register('defenseInvoice.invoice_no', { required: 'Required field' })}
                                >
                                </Form.Control>
                                {errors.defenseInvoice && errors.defenseInvoice.invoice_no && <p style={{ 'color': '#c70039' }}>{errors.defenseInvoice.invoice_no.message}</p>}
                            </Form.Group>
                        </div>
                        <div className='col-4'>
                            <Form.Group >
                                <Form.Label htmlFor=''>
                                    Invoice date<span style={{ 'color': '#c70039' }}>*</span>
                                </Form.Label>
                                <Form.Control name='invoice_date'
                                    type='date'
                                    placeholder=''
                                    {...register('defenseInvoice.invoice_date', { required: 'Required field' })}
                                >
                                </Form.Control>
                                {errors.defenseInvoice && errors.defenseInvoice.invoice_date && <p style={{ 'color': '#c70039' }}>{errors.defenseInvoice.invoice_date.message}</p>}
                            </Form.Group>
                        </div>
                        <div className='col-4'>
                            <Form.Group >
                                <Form.Label htmlFor=''>
                                    Order no<span style={{ 'color': '#c70039' }}>*</span>
                                </Form.Label>
                                <Form.Control name='order_no'
                                    type='text'
                                    placeholder=''
                                    {...register('defenseInvoice.order_no', { required: 'Required field' })}
                                >
                                </Form.Control>
                                {errors.defenseInvoice && errors.defenseInvoice.order_no && <p style={{ 'color': '#c70039' }}>{errors.defenseInvoice.order_no.message}</p>}
                            </Form.Group>
                        </div>
                        <div className='col-4'>
                            <Form.Group >
                                <Form.Label htmlFor=''>
                                    Order date<span style={{ 'color': '#c70039' }}>*</span>
                                </Form.Label>
                                <Form.Control name='order_date'
                                    type='date'
                                    placeholder=''
                                    {...register('defenseInvoice.order_date', { required: 'Required field' })}
                                >
                                </Form.Control>
                                {errors.defenseInvoice && errors.defenseInvoice.order_date && <p style={{ 'color': '#c70039' }}>{errors.defenseInvoice.order_date.message}</p>}
                            </Form.Group>
                        </div>
                        <div className='col-4'>
                            <Form.Group >
                                <Form.Label htmlFor=''>
                                    LF no<span style={{ 'color': '#c70039' }}>*</span>
                                </Form.Label>
                                <Form.Control name='lf_no'
                                    type='text'
                                    placeholder=''
                                    {...register('defenseInvoice.lf_no', { required: 'Required field' })}
                                >
                                </Form.Control>
                                {errors.defenseInvoice && errors.defenseInvoice.lf_no && <p style={{ 'color': '#c70039' }}>{errors.defenseInvoice.lf_no.message}</p>}
                            </Form.Group>
                        </div>
                        <div className='col-4'>
                            <Form.Group >
                                <Form.Label htmlFor=''>
                                    Buyer name<span style={{ 'color': '#c70039' }}>*</span>
                                </Form.Label>
                                <Form.Control name='buyer_name'
                                    type='text'
                                    placeholder=''
                                    {...register('defenseInvoice.buyer_name', { required: 'Required field' })}
                                >
                                </Form.Control>
                                {errors.defenseInvoice && errors.defenseInvoice.buyer_name && <p style={{ 'color': '#c70039' }}>{errors.defenseInvoice.buyer_name.message}</p>}
                            </Form.Group>
                        </div>
                        <div className='col-4'>
                            <Form.Group >
                                <Form.Label htmlFor=''>
                                    Item qty<span style={{ 'color': '#c70039' }}>*</span>
                                </Form.Label>
                                <Form.Control name='item_qty'
                                    type='number'
                                    placeholder=''
                                    {...register('defenseInvoice.item_qty', { required: 'Required field' })}
                                >
                                </Form.Control>
                                {errors.defenseInvoice && errors.defenseInvoice.item_qty && <p style={{ 'color': '#c70039' }}>{errors.defenseInvoice.item_qty.message}</p>}
                            </Form.Group>
                        </div>
                        <div className='col-4'>
                            <Form.Group >
                                <Form.Label htmlFor=''>
                                    Item UoM<span style={{ 'color': '#c70039' }}>*</span>
                                </Form.Label>
                                <Form.Control name='item_uom'
                                    type='text'
                                    placeholder=''
                                    {...register('defenseInvoice.item_uom', { required: 'Required field' })}
                                >
                                </Form.Control>
                                {errors.defenseInvoice && errors.defenseInvoice.item_uom && <p style={{ 'color': '#c70039' }}>{errors.defenseInvoice.item_uom.message}</p>}
                            </Form.Group>
                        </div>
                        <div className='col-12'>
                            <Form.Group >
                                <Form.Label htmlFor=''>
                                    Item description<span style={{ 'color': '#c70039' }}>*</span>
                                </Form.Label>
                                <Form.Control name='item_descr'
                                    as='textarea'
                                    placeholder=''
                                    rows={3}
                                    {...register('defenseInvoice.item_descr', { required: 'Required field' })}
                                >
                                </Form.Control>
                                {errors.defenseInvoice && errors.defenseInvoice.item_descr && <p style={{ 'color': '#c70039' }}>{errors.defenseInvoice.item_descr.message}</p>}
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className='modal-footer'>
                    <button className='btn btn-sm btn-primary' onClick={closeD1}>
                        Close
							</button>
                    <button className='btn btn-sm btn-primary' onClick={handleSubmit(saveDefenseInvoice)}>
                        Save
							</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
    _returnEnd()
}

export default InvoiceDocs;
_mainEnd()
