import axios from 'axios';

const urlRegistry = {
    "local": "http://localhost:5637/server_api",
    "remote": "http://xpediolive.com:5637/server_api"
}


const active_env = 'remote';

export async function callServerApi (api_name, data_jo, clause_jo) {
	const res = await axios.post(urlRegistry[active_env], { 'api': api_name, 'data': data_jo, 'clause': clause_jo });
	return res.data;
}

export async function callExternalApi (url, clause_data) {
	const res = await axios.post(url, clause_data);
	return res.data;
}

export async function uploadFile (file, form_data) {
	const formdata = new FormData();
	formdata.append('file', file, form_data['file_name']);
	const res = await axios.post(urlRegistry[active_env].replace('server_api','upload'), { 'api': 'upload', 'data': formdata});
	return res;
}

export async function sendMail(req){
	return await axios.post(urlRegistry[active_env], { 'api': 'sendMail', 'data': req});
}

export async function sendSms(req){
	return await axios.post(urlRegistry[active_env], { 'api': 'sendSms', 'data': req});
}
